<template>
    <div class="order-input-container">
        <div class="order-input-container-top">
            <van-nav-bar title="新增库存" fixed/>
        </div>
        <div class="order-input-container-form">
            <van-cell-group>
                <van-field name="type" label="调整方式">
                    <template #input>
                        <van-radio-group v-model="type" direction="horizontal">
                            <van-radio :name="1">增加</van-radio>
                            <van-radio :name="2">减少</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field name="num" label="调整数量">
                    <template #input>
                        <van-stepper v-if="type===1" v-model="num" min="1" />
                        <van-stepper v-else v-model="num" min="0" :max="form.stock" />
                    </template>
                </van-field>
            </van-cell-group>
        </div>
        <div class="order-input-container-btn">
            <van-button type="info" icon="passed" size="large" @click="onSubmit" text="提交"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'stock-input',
        components: {
        },
        props: ['data'],
        data() {
            return {
                cityList: [],
                picker: {
                    city: false,
                    stock: false,
                    forewarn: false
                },
                type: 1,
                num: 1,
                form: {
                    id: 0,
                    stock: 0
                }
            }
        },
        mounted() {
            this.form = this.data;
        },
        methods: {
            // 提交
            onSubmit() {
                let that = this;
                if(this.type === 1){
                    that.$api.stock.inc({
                        id: this.form.id,
                        num: this.num
                    }).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            that.$emit('onClose')
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                } else {
                    that.$api.stock.dec({
                        id: this.form.id,
                        num: this.num
                    }).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            that.$emit('onClose')
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .order-input-container {
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
    }

    .order-input-container-form {
        position: fixed;
        top: 46px;
        bottom: 50px;
        overflow-y: scroll;
        left: 0;
        right: 0;
    }

    .order-input-container-btn {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
    }

    .van-cell__value {
        flex: 3
    }

    .order-address {
        padding: 0 20px 10px;
    }

    .order-address-consignee {
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
    }

    .order-address-detail {
        color: #7d7e80;
        font-size: 12px;
        line-height: 16px;
    }

    .order-address-detail .van-tag {
        margin-right: 5px;
    }

    .order-address-detail .van-tag:last-child {
        margin-right: 0;
    }

    .goods-list {

    }

    .goods-list .goods-item {
        display: flex;
        padding: 10px 16px;
        color: #323233;
        font-size: 14px;
        line-height: 24px;

    }

    .goods-list .goods-item.border {
        border-bottom: 1px solid #ebedf0;
    }

    .goods-list .goods-item .goods-name {
        display: flex;
        flex: 2;
        position: relative;
        overflow: hidden;
        text-align: left;
        vertical-align: middle;
    }

    .goods-list .goods-item .goods-spec {
        position: relative;
        overflow: hidden;
        color: #969799;
        float: right;
    }

    .send-time-popup {
        position: relative;
    }

    .send-time-popup-title {
        position: relative;
        width: 100%;
        height: 46px;
        background: #fff;
    }

    .send-time-popup-title .van-nav-bar {
        position: fixed;
        width: 100%;
    }

    .send-time-list {
        padding: 4px;
    }

    .send-time-list .send-time-item {
        text-align: center;
        border: 1px solid #eee;
        margin: 4px;
        border-radius: 5px;
    }

    .send-time-list .send-time-item.active {
        border: 1px solid #1989fa;
        background-color: #1989fa40;
    }

    .send-time-list .send-time-item.disabled {
        background-color: #f5f5f5;
        color: #e0e0e0;
    }

</style>
