<template>
    <div class="stock-search-container">
        <div class="stock-search-container-top">
            <van-nav-bar title="库存管理" fixed/>
        </div>
        <div class="stock-search-container-search">
            <van-search placeholder="搜索一下" :disabled="true" show-action @click="picker.search=true">
                <div slot="action" @click="onOpenAdd">新增库存</div>
            </van-search>
        </div>
        <div class="stock-search-container-list">
            <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="finishedText"
                        @load="onLoad"
                >
                    <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                        <template slot="left">
                            <van-button square type="primary" icon="edit" text="调整" @click="onSetting(item)"/>
                        </template>
                        <van-cell :border="true" :value="item.stock + '库存'" :label="item.spec + ',' + item.city_name">
                            <template slot="title">
                                <div class="list-title-item">
                                    <van-tag round plain v-if="item.status===0">
                                        禁用
                                    </van-tag>
                                    <van-tag round plain type="success" v-else>
                                        启用
                                    </van-tag>
                                    <van-tag round plain v-if="item.forewarn===0">
                                        预警
                                    </van-tag>
                                    <van-tag round plain type="success" v-else>
                                        预警
                                    </van-tag>
                                    <span class="custom-title">{{item.name}}</span>
                                </div>
                            </template>
                        </van-cell>
                        <template slot="right">
                            <van-button square type="primary" icon="edit" text="修改" @click="onEdit(item)" />
                            <van-button v-if="item.status===0" square type="danger" icon="cross" text="删除" @click="onDel(item)" />
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <!-- 弹出区域 -->
        <div class="stock-search-container-popup">
            <!-- 搜索 -->
            <van-popup v-model="picker.search" position="right" :round="true" :style="{width:'80%',height:'100%'}">
                <van-cell-group>
                    <van-field
                            v-model="option.sku"
                            label="商品sku"
                            placeholder="请输入商品sku"
                            clearable
                    />
                    <van-field
                            v-model="option.name"
                            label="商品名称"
                            placeholder="请输入商品名称"
                            clearable
                    />
                    <van-field
                            v-model="option.statusName"
                            label="使用状态"
                            placeholder="请选择使用状态"
                            @focus="picker.status=true"
                            readonly
                    />
                    <van-field
                            v-model="option.forewarnName"
                            label="预警状态"
                            placeholder="请选择预警状态"
                            @focus="picker.forewarn=true"
                            readonly
                    />
                    <van-field
                            v-model="option.cityName"
                            label="所属城市"
                            placeholder="请选择所属城市"
                            @focus="picker.city=true"
                            readonly
                    />
                    <van-button type="info" icon="passed" size="large" @click="onSearch"
                                style="position:fixed;bottom:0;"
                                text="查询"/>
                </van-cell-group>
            </van-popup>
            <!-- 使用状态 -->
            <van-popup v-model="picker.status" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="使用状态"
                        :columns="statusList"
                        @confirm="onChangeStatus"
                        @cancel="picker.status=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 预警状态 -->
            <van-popup v-model="picker.forewarn" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="预警状态"
                        :columns="forewarnList"
                        @confirm="onChangeForewarnStatus"
                        @cancel="picker.forewarn=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 所属城市 -->
            <van-popup v-model="picker.city" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="所属城市"
                        :columns="cityList"
                        @confirm="onChangeCity"
                        @cancel="picker.city=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 新增｜修改 -->
            <van-popup v-model="picker.form" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <addForm :data.sync="form" :key="form.id" @onClose="onCloseAdd"/>
            </van-popup>
            <!-- 加减库存 -->
            <van-popup v-model="picker.setting" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <settingForm :data.sync="form" :key="form.id" @onClose="onCloseSetting"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import addForm from './input'
    import settingForm from './setting'

    export default {
        name: "stock-search",
        components: {
            addForm,
            settingForm,
        },
        data() {
            return {
                freshing: false,
                auth: this.$store.state.user.auth || [],
                list: [],
                statusList: [
                    {index: '', text: '全部'},
                    {index: 0, text: '禁用'},
                    {index: 1, text: '启用'}
                ],
                forewarnList: [
                    {index: '', text: '全部'},
                    {index: 0, text: '关闭'},
                    {index: 1, text: '开启'}
                ],
                cityList: [],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    sku: '',
                    name: '',
                    status: '',
                    statusName: '',
                    forewarn: '',
                    forewarnName: '',
                    cityId: '',
                    cityName: '',
                },
                picker: {
                    search: false,
                    city: false,
                    status: false,
                    forewarn: false,
                    form: false,
                    setting: false,
                },
                form: {
                    city_id: '',
                    city_ids: [],
                    city_name: '',
                    complete: 0,
                    forewarn: 0,
                    forewarn_num: 0,
                    id: 0,
                    sku: '',
                    name: '',
                    spec: '',
                    status: 0,
                    stock: 0,
                    total: 0,
                },
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init() {
                this.loadCity();
                this.onLoad();
            },
            loadCity() {
                let that = this;
                if (that.cityList.length === 0) {
                    that.$api.region.loadCity(0).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            res.data.unshift({index: 0, text: '全部'});
                            that.cityList = res.data;
                        }
                    });
                }
            },
            onChangeStatus(value) {
                this.option.status = value.index;
                this.option.statusName = value.text;
                this.picker.status = false;
            },
            onChangeForewarnStatus(value) {
                this.option.forewarn = value.index;
                this.option.forewarnName = value.text;
                this.picker.forewarn = false;
            },
            onChangeCity(value) {
                this.option.cityId = value.index;
                this.option.cityName = value.text;
                this.picker.city = false;
            },
            onSearch() {
                this.onLoad()
            },
            onRefresh() {
                this.freshing = false;
                this.onLoad();
            },
            onLoad() {
                let that = this;
                that.picker.search = false;
                that.list = [];
                that.loading = true;
                that.finished = false;
                that.$api.stock.search(that.option).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code === 1) {
                        if (res.data.length === 0) {
                            that.finishedText = '暂无数据'
                        } else {
                            that.finishedText = ''
                        }
                        that.list = res.data;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            },
            onOpenAdd() {
                this.form = {
                    city_id: '',
                    city_ids: [],
                    city_name: '',
                    complete: 0,
                    forewarn: 0,
                    forewarn_num: 0,
                    id: 0,
                    sku: '',
                    name: '',
                    spec: '',
                    status: 0,
                    stock: 0,
                    total: 0,
                };
                this.picker.form = true;
            },
            onCloseAdd() {
                this.picker.form = false;
                this.onSearch();
            },
            onCloseSetting() {
                this.picker.setting = false;
                this.onSearch();
            },
            // 调整库存
            onSetting(item) {
                this.form = item;
                this.picker.setting = true;
            },
            // 修改订单
            onEdit(item) {
                this.form = item;
                this.picker.form = true;
            },
            // 删除
            onDel(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '操作不可逆，确认删除吗？'
                }).then(() => {
                    that.$api.stock.del({
                        id: item.id
                    }).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            that.$toast.success(res.msg);
                            that.onLoad();
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }).catch(() => {
                    // on cancel
                });
            }
        }
    }
</script>

<style scoped>
    .stock-search-container {
        padding-top: 46px;
    }

    .van-cell__title {
        flex: 2
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

    .stock-search-container-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .stock-search-container-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .list-title-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .custom-title {
        margin-left: 6px;
    }

    .stock-search-container-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }
</style>
